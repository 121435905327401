import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allCountry: [],
};

export const countrySlice = createSlice({
  name: "All Country",
  initialState,
  reducers: {
    countryAction: (state, action) => {
      state.allCountry = action.payload;
    },
  },
});

export const { countryAction } = countrySlice.actions;

export default countrySlice.reducer;
