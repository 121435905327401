import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  topProductsData: [],
  allProductsData: [],
};

export const productSlice = createSlice({
  name: "Products Info",
  initialState,
  reducers: {
    topProductsSlice: (state, action) => {
      state.topProductsData = action.payload;
    },
    allProductsSlice: (state, action) => {
      state.allProductsData = action.payload;
    },
  },
});

export const { topProductsSlice, allProductsSlice } = productSlice.actions;

export default productSlice.reducer;
