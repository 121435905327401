import axios from "@axios/axiousConfig";

//endPoint
const endPointSignup = "/auth/user/register";
const endPointLogin = "/auth/user/login";
const tokenVerify = "/auth/user/verify-token";

// User-SignUp-API-start
export const userAccountRegister = async (data) => {
  try {
    const response = await axios.post(endPointSignup, data);
    return response;
  } catch (error) {
    console.log("ERROR On Account User SignUp API:", error);
    if (error.response) {
      return error.response;
    }
  }
};
// user-SignUp-API-End

// User-Login-API-start
export const userAccountLogin = async (data) => {
  try {
    const response = await axios.post(endPointLogin, data);
    return response;
  } catch (error) {
    console.log("ERROR On Account User Login API:", error);
    if (error.response) {
      return error.response;
    }
  }
};
// user-login-API-End

// Token-verify-API-start
export const verifyTokenAndFetchUser = async (data) => {
  try {
    const response = await axios.post(tokenVerify, data);
    return response;
  } catch (error) {
    console.log("ERROR On Account User Login API:", error);
    if (error.response) {
      return error.response;
    }
  }
};
// Token-verify-API-End

// Generate-Token-using-email-for-reset-passwords-start
export const generateTokenByEmail = async (data) => {
  const generateTokenEndPoint = "/auth/user/forget-password/generateToken";
  try {
    const response = await axios.post(generateTokenEndPoint, data);
    return response;
  } catch (error) {
    console.log("ERROR On Generate Token By EMail API:", error);
    if (error.response) {
      return error.response;
    }
  }
};
// Generate-Token-using-email-for-reset-passwords-end

// Token-verify-for-reset-passwords-start
export const verifyTokenResetPassword = async (data, token) => {
  const verifyTokenEndPoint = `/auth/user/forget-password/verifyToken/${token}`;
  try {
    const response = await axios.post(verifyTokenEndPoint, data);
    return response;
  } catch (error) {
    console.log("ERROR On Verify Token For Reset password API:", error);
    if (error.response) {
      return error.response;
    }
  }
};
// Token-verify-for-reset-passwords-end

// Reset-Password-API-STart
export const resetPasswordApi = async (data) => {
  const resetPasswordEndPoint = `/auth/user/forget-password/reset-password`;
  try {
    const response = await axios.post(resetPasswordEndPoint, data);
    return response;
  } catch (error) {
    console.log("ERROR On Verify Token For Reset password API:", error);
    if (error.response) {
      return error.response;
    }
  }
};
// Reset-Password-API-End

// verify-account-API-start
export const verifyAccount = async (token) => {
  try {
    const verifyAccountPath = `/auth/user/verify-account/${token}`;

    const response = await axios.post(verifyAccountPath, {});
    return response;
  } catch (error) {
    console.log("ERROR On Account User Verify API:", error);
    if (error.response) {
      return error.response;
    }
  }
};
// verify-account-API-End
