import React, { useEffect, useState } from "react";
import Routes from "@routes/routes";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
// Model
import TokenExpireModel from "@components/Models/TokenExpire";
//Services
import { verifyTokenAndFetchUser } from "@services/auth";
import { getCartByUserIdApi } from "@services/cart";
//redux
import { useSelector, useDispatch } from "react-redux";
import { loginData, loginSignal } from "@slice/Login/loginSlice";
import {
  cartItems,
  updateCart,
  cartItemsCount,
} from "@slice/CartItems/cartItemsSlice";
import {
  subTotalSlice,
  grandTotalSlice,
  shippingSlice,
  orignalTotalSlice,
} from "@slice/TotalAmount/amountSlice";

function App() {
  const [cartFlag, setCartFlag] = useState(true);
  const [tokenModel, setTokenModel] = useState(false);
  const dispatch = useDispatch();
  const { signal, loginInfo } = useSelector((state) => state.loginInfo);
  const cartUpdate = useSelector((state) => state.cartInfo.updateCartItems);

  //fetching admin data through token start
  const fetchAndVerifyAdmin = async (token) => {
    const params = {
      token: token,
    };
    const response = await verifyTokenAndFetchUser(params).then((data) => {
      return data;
    });
    console.log("Response from token verify Api: ", response.data);
    const adminData = response.data.data;
    if (response.data.status === "200") {
      dispatch(loginData(adminData || {}));
    } else {
      setTokenModel(true);
    }
  };

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("TOKEN"));

    if (signal) {
      dispatch(loginSignal(false));
      if (token) {
        console.log("Token available: ", token);
        fetchAndVerifyAdmin(token);
      }
    }
  }, [signal]);
  //fetching admin data through token end

  // Cart-Items-Update-Code-Start
  // Get-Cart-By-User-Id-start
  const getCartFun = async (id, status = false) => {
    if (cartFlag || status) {
      setCartFlag(false);
      await getCartByUserIdApi({}, id).then((response) => {
        console.log("GET CART BY USER IDD", response);
        if (response.data.status === "200") {
          const allData = response.data.data;
          if (allData?.productDetails?.length > 0) {
            let filterCart = allData.productDetails.map((val) => {
              return {
                productId: val["productId"]._id,
                quantity: val.quantity,
                image: val["productId"]["images"][0],
                title: val["productId"].name,
                price: val["productId"].price,
                stock: val["productId"].stock,
                sellerId: val["productId"].sellerId,
              };
            });
            dispatch(cartItems(filterCart));
            dispatch(cartItemsCount(filterCart?.length));
            localStorage.setItem("PROD_STORE", JSON.stringify(filterCart));
          }
        }
      });
    }
  };
  // Get-Cart-By-User-Id-end

  useEffect(() => {
    if (Object.keys(loginInfo).length > 0) {
      getCartFun(loginInfo._id, true);
    }
  }, [loginInfo]);

  useEffect(() => {
    const discountCode = JSON.parse(localStorage.getItem("DISCOUNT_CODE"));
    const CartData = JSON.parse(localStorage.getItem("PROD_STORE"));
    const price = JSON.parse(localStorage.getItem("PROD_EXPENSE"));

    let grandTotal = 0;
    let orignalTotal = 0;

    if (discountCode?.code) {
      orignalTotal = Number(price?.subTotal) + Number(price?.shipping);
      const percentage = (orignalTotal / 100) * Number(discountCode.discount);
      grandTotal = (orignalTotal - percentage).toFixed(2);
    } else {
      grandTotal = price?.total || 0;
    }

    dispatch(updateCart(false));
    dispatch(cartItems(CartData || []));
    dispatch(cartItemsCount(CartData?.length || 0));
    dispatch(subTotalSlice(price?.subTotal || 0));
    dispatch(grandTotalSlice(grandTotal || 0));
    dispatch(shippingSlice(price?.shipping || 0));
    dispatch(orignalTotalSlice(orignalTotal || 0));
  }, [cartUpdate]);

  // Cart-Items-Update-Code-End

  return (
    <>
      <TokenExpireModel show={tokenModel} onHide={() => setTokenModel(false)} />
      <Routes />
    </>
  );
}

export default App;
