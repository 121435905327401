import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allAddress: [],
};

export const addressSlice = createSlice({
  name: "All Address",
  initialState,
  reducers: {
    addressAction: (state, action) => {
      state.allAddress = action.payload;
    },
  },
});

export const { addressAction } = addressSlice.actions;

export default addressSlice.reducer;
