import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  values: "",
};

export const searchProducts = createSlice({
  name: "Products Search",
  initialState,
  reducers: {
    searchProdSlice: (state, action) => {
      state.values = action.payload;
    },
  },
});

export const { searchProdSlice } = searchProducts.actions;

export default searchProducts.reducer;
