import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "@slice/Login/loginSlice";
import productSlice from "@slice/Products/productSlice";
import amountDataReducer from "@slice/TotalAmount/amountSlice";
import cartItemsReducer from "@slice/CartItems/cartItemsSlice";
import addressSlice from "@slice/Address/addressSlice";
import categorySlice from "@slice/Category/categorySlice";
import searchProdReducer from "@slice/ProductSearch/searchSlice";
import countrySlice from "@slice/Country/countrySlice";
import signalReducer from "../slice/signalSlice";

export const store = configureStore({
  reducer: {
    loginInfo: loginReducer,
    productsInfo: productSlice,
    totalAmount: amountDataReducer,
    cartInfo: cartItemsReducer,
    addressInfo: addressSlice,
    categoryInfo: categorySlice,
    searchValues: searchProdReducer,
    countryInfo: countrySlice,
    signal: signalReducer,
  },
});
