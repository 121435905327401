import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allCategories: [],
  searchCategorie: {},
};

export const categorySlice = createSlice({
  name: "All Categories",
  initialState,
  reducers: {
    categoryAction: (state, action) => {
      state.allCategories = action.payload;
    },
    searchCategoryAction: (state, action) => {
      state.searchCategorie = action.payload;
    },
  },
});

export const { categoryAction, searchCategoryAction } = categorySlice.actions;

export default categorySlice.reducer;
