import axios from "@axios/axiousConfig";

// Add-To-Cart-API-start
export const addToCartApi = async (data) => {
  try {
    // endPoint
    const endPoint = `/cart`;
    const response = await axios.post(endPoint, data);
    return response;
  } catch (error) {
    console.log("Error on Add-To-Cart API: ", error);
    if (error.response) {
      return error.response;
    }
  }
};
// Add-To-Cart-API-End
// Get-Cart-By-User-Id-Start
export const getCartByUserIdApi = async (data, id) => {
  try {
    // endPoint
    const endPoint = `/cart/specific/user/${id}`;
    const response = await axios.post(endPoint, data);
    return response;
  } catch (error) {
    console.log("Error on Get-Cart-By-User-Id API: ", error);
    if (error.response) {
      return error.response;
    }
  }
};
// Get-Cart-By-User-Id-end
