import React, { Suspense, lazy } from "react";
// ----3rd-party---- //
import { Routes, Route } from "react-router-dom";
// ----Component-for-scroll-to-top---- //
import ScrollToTop from "@components/ScrollToTop/ScrollToTop";
// ----Loader---- //
import { PulseLoader } from "react-spinners";
// Local-Storages
import { tokenStorage } from "@localStorage";
// Protected-routes
import { PrivateLogin, PublicRoute } from "./PrivateRoute";
// ----pages---- //
const Home = lazy(() => import("@pages/Home"));
const Login = lazy(() => import("@pages/Login"));
const Signup = lazy(() => import("@pages/Signup"));
const ForgetPassword = lazy(() => import("@pages/ForgetPassword"));
const ResetPassword = lazy(() => import("@pages/ResetPassword"));
const ProductDetails = lazy(() => import("@pages/ProductDetails"));
const Cart = lazy(() => import("@pages/Cart"));
const Information = lazy(() => import("@pages/Information"));
const Payment = lazy(() => import("@pages/Payment"));
const FAQs = lazy(() => import("@pages/FAQs"));
const TrackOrder = lazy(() => import("@pages/TrackOrder"));
const TrackOrderById = lazy(() => import("@pages/TrackOrderById"));
const Orders = lazy(() => import("@pages/Orders"));
const EmptyCart = lazy(() => import("@pages/EmptyCart"));
const AccountSettings = lazy(() => import("@pages/AccountSettings"));
const ReturnOrderQR = lazy(() => import("@pages/ReturnOrderQR"));
const OrderDetails = lazy(() => import("@pages/OrderDetails"));
const WebsiteDetails = lazy(() => import("@pages/WebsiteDetails"));
const PrivacyPolicy = lazy(() => import("@pages/PrivacyPolicy"));
const ReturnsPolicy = lazy(() => import("@pages/ReturnsPolicy"));
const TermsAndConditions = lazy(() => import("@pages/Terms&Conditions"));
const AboutUs = lazy(() => import("@pages/AboutUs"));
const ContactUs = lazy(() => import("@pages/ContactUs"));
const Address = lazy(() => import("@pages/Address"));
const OrderReturnSelection = lazy(() => import("@pages/OrderReturnSelection"));
const OrderReturnPayment = lazy(() => import("@pages/OrderReturnPayment"));
const ProductSearch = lazy(() => import("@pages/ProductSearch"));
const AccountActivation = lazy(() => import("@pages/AccountActivation"));

//config
import config from "@config/config.json";

const url = config.routeUrl;

const spinnerStyle = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

export default function routes() {
  return (
    <>
      <Suspense
        fallback={
          <PulseLoader color={"#030870"} size={17} css={spinnerStyle} />
        }
      >
        <Routes>
          <Route path={url} element={<Home />} />
          {/* When-login-not-Access-to-These-Routes-Start */}
          <Route path={url + "login"} element={<Login />} exact />
          <Route path={url + "signup"} element={<Signup />} exact />
          {/* When-login-not-Access-to-These-Routes-End */}
          {/* Must-login-to-Access-these-Route-start */}
          <Route element={<PrivateLogin isAllowed={tokenStorage()} />}>
            <Route path={url + "track-order"} element={<TrackOrder />} />
            <Route path={url + "orders"} element={<Orders />} />
            <Route
              path={url + "account-settings"}
              element={<AccountSettings />}
            />
            <Route path={url + "my-address"} element={<Address />} />
            <Route path={url + "info"} element={<Information />} />
            <Route path={url + "payment"} element={<Payment />} />
            <Route path={url + "order-details"} element={<OrderDetails />} />
            <Route
              path={url + "order-return-selection"}
              element={<OrderReturnSelection />}
            />
            <Route
              path={url + "order-return-payment"}
              element={<OrderReturnPayment />}
            />
            <Route
              path={url + "track-order-by-id"}
              element={<TrackOrderById />}
            />
          </Route>
          {/* Must-login-to-Access-these-Route-end */}
          <Route path={url + "forget-password"} element={<ForgetPassword />} />
          <Route
            path={url + "reset-password/:Token"}
            element={<ResetPassword />}
          />
          <Route path={url + "product-detail"} element={<ProductDetails />} />
          <Route path={url + "cart"} element={<Cart />} />

          <Route path={url + "empty-cart"} element={<EmptyCart />} />

          <Route path={url + "print-qr"} element={<ReturnOrderQR />} />

          <Route path={url + "product-search"} element={<ProductSearch />} />
          <Route path={url + "faqs"} element={<FAQs />} />
          <Route path={url + "website-details"} element={<WebsiteDetails />} />
          <Route path={url + "privacy-policy"} element={<PrivacyPolicy />} />
          <Route path={url + "return-policy"} element={<ReturnsPolicy />} />
          <Route
            path={url + "terms-and-conditions"}
            element={<TermsAndConditions />}
          />
          <Route path={url + "about-us"} element={<AboutUs />} />
          <Route path={url + "contact-us"} element={<ContactUs />} />
          <Route
            path={url + "verify-account/:Token"}
            element={<AccountActivation />}
          />
          <Route path={url + "*"} element={<div>Page not Found</div>} />
        </Routes>
        <ScrollToTop />
      </Suspense>
    </>
  );
}
