import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: false,
};

export const signalSlice = createSlice({
  name: "signal",
  initialState,
  reducers: {
    signal: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { signal } = signalSlice.actions;

export default signalSlice.reducer;
