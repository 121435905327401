import { render } from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
// Redux-store
import { store } from "@store/store";
// Redux-provider
import { Provider } from "react-redux";
// MUI
import { ThemeProvider } from "@mui/material";
// Theme
import theme from "@theme"


render(
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </ThemeProvider>,
  document.getElementById("root")
);
