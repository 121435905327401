import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cartItems: [],
  count: 0,
  updateCartItems: false,
  cartLoading: false,
};

export const cartItemsSlice = createSlice({
  name: "All carts Items",
  initialState,
  reducers: {
    cartItems: (state, action) => {
      state.cartItems = action.payload;
    },
    cartItemsCount: (state, action) => {
      state.count = action.payload;
    },
    updateCart: (state, action) => {
      state.updateCartItems = action.payload;
    },
    cartLoaded: (state, action) => {
      state.cartLoading = action.payload;
    },
  },
});

export const { cartItemsCount, updateCart, cartItems, cartLoaded } =
  cartItemsSlice.actions;

export default cartItemsSlice.reducer;
